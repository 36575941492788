import { initSDK, buildModule } from '@vue-storefront/sdk';
import { type PrestashopModuleType, prestashopModule } from '@vue-storefront/prestashop-sdk';
import { craftCmsModule, type CraftCmsModuleType } from '@vue-storefront/craftcms-sdk';

const _currencies = {
  '1': 'EUR',
  '2': 'DZD',
  '3': 'USD',
  '4': 'DZD',
  '5': 'ARS',
  '6': 'AUD',
  '8': 'CHF',
  '9': 'CAD',
  '10': 'RUB',
  '11': 'PLN',
  '12': 'SEK',
  '13': 'USD',
  '14': 'GBP',
};

export const useSdk = () => {
  setLocaleCookie();
  const country = setCountryCookie();
  const currencyCookie = setCurrencyCookie();

  const { $i18n } = useNuxtApp();
  const headers = useRequestHeaders();
  const runtimeConfig = useRuntimeConfig();

  const newHeaders = { ...headers };
  delete newHeaders['host'];

  if (newHeaders && newHeaders['cookie']) {
    newHeaders['cookie'] = newHeaders['cookie']
      .replace(/vsf-locale=.*?(;|$)/, 'vsf-locale=' + $i18n.locale.value + ';')
      .replace(/vsf-currency=.*?;/, 'vsf-currency=' + currencyCookie.value + ';')
      .replace(/vsf-country=.*?;/, 'vsf-country=' + country.value + ';');
  } else {
    newHeaders['x-vsf-locale'] = $i18n.locale.value ?? '';
    // newHeaders['x-vsf-currency'] = currencyCookie.value ?? '';
    // newHeaders['x-vsf-country'] = country.value ?? '';
  }

  const sdkConfig = {
    commerce: buildModule<PrestashopModuleType>(prestashopModule, {
      apiUrl: runtimeConfig.public.middleware_url + '/prestashop',
      headers: newHeaders,
    }),
    cms: buildModule<CraftCmsModuleType>(craftCmsModule, {
      apiUrl: runtimeConfig.public.middleware_url + '/craftcms',
      headers: newHeaders,
    }),
  };

  return initSDK<typeof sdkConfig>(sdkConfig);
};

function setLocaleCookie() {
  const nuxtApp = useNuxtApp();
  const localeCookie = useCookie('vsf-locale');
  const redirectCookie = useCookie('i18n_redirected');

  if (redirectCookie.value) {
    localeCookie.value = redirectCookie.value;
  }
  if (localeCookie.value != nuxtApp.$i18n.locale.value) {
    localeCookie.value = nuxtApp.$i18n.locale.value;
  }

  return localeCookie;
}

function setCountryCookie() {
  const nuxtApp = useNuxtApp();
  const country = useCookie('vsf-country');

  if (nuxtApp.$router.currentRoute.value.query.id_country) {
    country.value = nuxtApp.$router.currentRoute.value.query.id_country as string;
  }

  return country;
}

function setCurrencyCookie() {
  const nuxtApp = useNuxtApp();
  const currency = useCookie('vsf-currency');

  if (nuxtApp.$router.currentRoute.value.query.iso_currency) {
    currency.value = nuxtApp.$router.currentRoute.value.query.iso_currency as string;
  } else if (nuxtApp.$router.currentRoute.value.query.id_currency) {
    // @ts-ignore
    currency.value = _currencies[nuxtApp.$router.currentRoute.value.query.id_currency ?? '1'] ?? 'EUR';
  } else if (!currency.value) {
    currency.value = 'EUR';
  }

  return currency;
}
